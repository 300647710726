<template>
    <div class="navIndex">
        <Toast/>
        <ConnectWallet/>
    </div>
</template>

<script>
    import Toast from './Toast'
    import ConnectWallet from './ConnectWallet'

    export default {
        components: {Toast, ConnectWallet}
    }
</script>

<style lang="less">
    .navIndex {
    }
</style>
