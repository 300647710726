<template>
    <div class="connectWallet">
        <van-dialog v-model="$store.state.connectWalletShow" show-cancel-button :showConfirmButton="false"
                    :showCancelButton="false" title="">
            <div class="wrap">
                <div class="sec1">
                    <div class="d1">
                        <em>Connect to a Wallet on BSC</em>
                    </div>
                    <div class="d2" @click="initWeb3()" v-if="$store.state.accounts.length === 0">
                        <img src="../../assets/images/pc/icon_metamask.png"/>
                        Meta Mask
                    </div>
                    <div class="d2" @click="hide(),$store.dispatch('logOut')" v-if="$store.state.accounts.length !== 0">
                        <img src="../../assets/images/pc/icon_metamask.png"/>
                        Disconnect
                    </div>
                    <div class="d3">
                        <a>Learn how to connect? ></a>
                    </div>
                    <img @click="hide()" class="img1" src="../../assets/images/pc/X.png"/>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
    import {lang} from "../../tools";
    import * as metaMaskTools from "../../tools/chain";

    export default {
        name: 'connectWallet',
        methods: {
            lang(key) {
                return lang(key);
            },
            initWeb3() {
                metaMaskTools.initWeb3().then(() => {
                    this.hide();
                });
            },
            hide() {
                this.$store.commit('setState', {
                    connectWalletShow: false
                })
            }
        }
    }
</script>

<style lang="less">
    .connectWallet {
        .van-dialog {
            width: 380px !important;
            background: #393939;
            border-radius: 12px;
        }
        .wrap {
            > .sec1 {
                height: 225px;
                background: #515151;
                border-radius: 12px;
                position: relative;
                > .img1 {
                    width: 12px;
                    height: 12px;
                    cursor: pointer;
                    position: absolute;
                    right: 25px;
                    top: 20px;
                }
                .d1 {
                    font-size: 16px;
                    color: #fff;
                    height: 60px;
                    line-height: 60px;
                    font-weight: 600;
                    text-align: center;
                    background: url("../../assets/images/pc/img_logo.png") no-repeat 30px center;
                    background-size: 28px 28px;
                    margin-bottom: 30px;
                    border-bottom: 2px solid #FFFFFF !important;
                }
                em {
                    margin-left: -30px;
                    color: #E49B00;
                }
                .d2 {
                    margin-left: 10%;
                    width: 65%;
                    margin-top: 20px;
                    height: 60px;
                    border-radius: 60px;
                    padding: 0 27px;
                    font-size: 18px;
                    color: #E49B00;
                    background: #363535;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                    margin-bottom: 20px;
                    transition: all 0.7s;
                    img {
                        width: 40px;
                        height: 40px;
                        margin-right: 10px;
                    }
                }
                .d2:hover {
                    background: #272626;
                }
                .d3 {
                    text-align: center;
                    height: 20px;
                    font-size: 18px;
                    font-family: Poppins;
                    font-weight: 500;
                    text-decoration: underline;
                    color: #E49B00;
                    line-height: 17px;
                    a {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .connectWallet {
            .van-dialog {
                width: 300px;
            }
            .wrap {
                > .sec1 {
                    width: auto;
                    height: auto;
                    overflow: hidden;
                    padding: 0 0 30px;
                    .d1 {
                        text-align: left;
                        padding-left: 55px;
                        font-size: 13px;
                        margin-bottom: 0;
                    }
                    .d2 {
                        zoom: 1.2;
                        font-size: 16px;
                        margin-bottom: 0;
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                    em {
                        margin-left: 10px;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: #ffffff;
                        line-height: 32px;
                    }
                }
                .d3 {
                    margin-top: 10px;
                }
            }
        }
    }
</style>
