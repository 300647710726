<template>
    <!--铸币弹窗-->
    <div class="Toast">
        <van-dialog v-model="$store.state.toastShow" show-cancel-button :showConfirmButton="false"
                    :showCancelButton="false" title="">
            <div class="sec1">
                <img @click="hide" class="imgClose" src="../../assets/images/pc/btn_close.png"/>
                <div v-if="$store.state.toastShowType === 0">
                    <div class="d1">
                        <img class="img2" src="../../assets/images/pc/img_logo.png"/>
                    </div>
                    <div class="d2">
                        Confirmation in progress...
                    </div>
                    <div class="d3">
                        <button @click="hide">
                            well
                        </button>
                    </div>
                </div>
                <div v-if="$store.state.toastShowType === 1">
                    <div class="d1">
                        <img class="img1" src="../../assets/images/pc/img_logo.png"/>
                    </div>
                    <div class="d2 mb50">
                        Transaction broadcast...
                    </div>
                    <div class="d3" v-show="$store.state.transactionHash !== ''">
                        <a :href="'https://'+ OpenUrlSLD +'com/tx/' + $store.state.transactionHash"
                           target="_blank">
                            <button>
                                View transactions
                            </button>
                        </a>
                    </div>
                    <div class="d3" v-show="$store.state.transactionHash === ''">
                        <button @click="hide">
                            well
                        </button>
                    </div>
                </div>
                <div v-if="$store.state.toastShowType === 2">
                    <div class="d1">
                        <img class="img2" src="../../assets/images/pc/img_logo.png"/>
                    </div>
                    <div class="d2 mb50">
                        Successful trade
                    </div>
                    <div class="d3" v-show="$store.state.transactionHash !== ''">
                        <a :href="'https://'+ OpenUrlSLD +'com/tx/' + $store.state.transactionHash"
                           target="_blank">
                            <button>
                                View transactions
                            </button>
                        </a>
                    </div>
                </div>
                <div v-if="$store.state.toastShowType === 3">
                    <div class="d1">
                        <img class="img2" src="../../assets/images/pc/img_logo.png"/>
                    </div>
                    <div class="d2 mb50">
                        Transaction failed
                    </div>
                    <div class="d3" v-show="$store.state.transactionHash !== ''">
                        <a :href="'https://'+ OpenUrlSLD +'com/tx/' + $store.state.transactionHash"
                           target="_blank">
                            <button>
                                View transactions
                            </button>
                        </a>
                    </div>
                    <div class="d3" v-show="$store.state.transactionHash === ''">
                        <button @click="hide">
                            well
                        </button>
                    </div>
                </div>
                <div v-if="$store.state.toastShowType === 4">
                    <div class="d1">
                        <img class="img2" src="../../assets/images/pc/img_logo.png"/>
                    </div>
                    <div class="d2 mb50">
                        Transaction cancellation
                    </div>
                    <div class="d3">
                        <button @click="hide">
                            well
                        </button>
                    </div>
                </div>
            </div>
        </van-dialog>

        <van-dialog v-model="$store.state.dialogContact" show-cancel-button :showConfirmButton="false" :showCancelButton="false" title="">
            <div class="dialog_contact_div">
                <div class="contact_top">
                    <img src="../../assets/images/pc/X.png" @click="hide">
                </div>
                <div class="contact_text">
                    E-mail:info@cheersland.org
                </div>
                <div class="contact_button">
                    <div class="bottom_copy" @click="copyClick(contactText)">COPY</div>
                </div>
                <div class="contact_bg"/>
            </div>
        </van-dialog>

        <van-dialog v-model="$store.state.dialogInviteFriends" show-cancel-button :showConfirmButton="false" :showCancelButton="false" title="">
            <div class="dialog_invite_friends_div">
                <div class="friend_top">
                    <img src="../../assets/images/pc/X.png" @click="hide">
                </div>
                <div class="friend_title">
                    Invite to Earn
                </div>
                <div class="friend_text">
                    Invite your friend to join the
                    games in CheersLand by
                    following ink to earn an extra
                    HP both!
                    <br v-show="linkCodeText === null">
                    {{linkCodeText}}
                    <i v-show="linkCodeText === null" class="el-icon-loading" style="font-size: 30px;text-align: center;margin: 0 auto;margin-top: 5px;margin-left: 45%;"></i>
                    <div v-show="linkCodeText === null" class="loading">Loading...</div>
                </div>
                <div class="friend_button">
                    <div class="bottom_copy" @click="copyClick(linkCode)">COPY</div>
                </div>
                <div class="friend_bg"/>
            </div>
        </van-dialog>

    </div>
</template>

<script>
    import {
        lang,
        Toast,
        BigNumber,
        BigNumberStr,
        BigNumberMul,
        BigNumberDiv,
        BigNumberAdd,
        BigNumberSub,
        cutAccount,
    } from "../../tools";
    import {OpenUrlSLD} from '../../config'
    import transfer from "../../tools/transfer";

    export default {
        name: 'Toast',
        data() {
            return {
                OpenUrlSLD,
                linkCode: null,
                linkCodeText: null,
                contactText: 'info@cheersland.org',
            }
        },
        created() {
            var that = this
            transfer.$on('openDialogContact', function () {
                that.openDialogContact()
            })
            transfer.$on('openDialogInviteFriends', function (val) {
                that.openDialogInviteFriends(val)
            })
        },
        mounted() {
        },
        methods: {
            lang(key) {
                return lang(key);
            },
            cutAccount(account, num) {
                return cutAccount(account, num);
            },
            hide() {
                this.$store.commit('setState', {
                    toastShow: false,
                    dialogContact: false,
                    dialogInviteFriends: false
                })
            },
            openDialogContact() {
                this.$store.commit('setState', {
                    dialogContact: true
                })
            },
            openDialogInviteFriends(address) {
                this.$store.commit('setState', {
                    dialogInviteFriends: true
                })
                this.$http.send(
                    address,
                    []
                ).then(res => {
                    console.log("res: ", res);
                    this.linkCode = 'https://cheersland.org/share/' + res.code
                    this.linkCodeText = 'https://cheersland.org/share/' + this.cutAccount(res.code, 8)
                }).catch(err => {
                    console.log("err: ", err);
                })
            },
            copyClick(val) {
                // 创建一个 Input标签
                const cInput = document.createElement('input')
                cInput.value = val
                document.body.appendChild(cInput)
                // 选取文本域内容;
                cInput.select()
                // 执行浏览器复制命令
                // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
                // Input要在正常的编辑状态下原生复制方法才会生效
                document.execCommand('Copy')
                this.$message('Copy succeeded!');
                /// 复制成功后再将构造的标签 移除
                cInput.remove()
            },
        }
    }
</script>

<style lang="less">
    .el-message {
        z-index: 3001 !important;
    }
    .van-overlay {
        z-index: 2001 !important;
    }
    .Toast {
        letter-spacing: 3px;

        .van-dialog {
            width: 380px;
            background-color: #393939;
            box-shadow: 11px 11px 0px 0px rgba(0, 0, 0, 0.3);
        }

        .sec1 {
            text-align: center;
            width: 380px;
            background: #393939;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 40px 0 20px;

            .imgClose {
                width: 12px;
                height: 12px;
                cursor: pointer;
                position: absolute;
                right: 25px;
                top: 20px;
            }

            .d1 {
                display: flex;
                justify-content: center;

                img {
                    width: 90px;
                    height: 90px;
                }

                .img1 {
                    width: 120px;
                    height: 84px;
                }

                .img2 {
                    min-width: 120px;
                    width: 120px;
                    min-height: 100px;
                    height: 100px;
                }

                margin-bottom: 20px;
            }

            .d2 {
                font-size: 15px;
                text-align: center;
                margin-bottom: 50px;
                font-family: PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 20px;
            }

            .d3 {
                button {
                    min-width: 120px;
                    margin-top: -15px;
                    height: 50px;
                    border: 2px solid #e6a23c;
                    border-radius: 12px;
                    background: #e6a23c;
                    font-size: 14px;
                    text-align: center;
                    cursor: pointer;
                    letter-spacing: 2px;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 20px;
                }
            }
        }

        .dialog_contact_div {
            width: 380px;
            height: 241px;
            background: #1D1D1D;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            .contact_top {
                width: 6px;
                margin-left: 325px;
                margin-top: -15px;
                cursor: pointer;
                img {
                    width: 16px;
                }
            }
            .contact_text {
                width: 350px;
                height: 24px;
                font-size: 18px;
                font-family: Poppins;
                font-weight: 600;
                color: #FFFFFF;
                text-align: center;
            }
            .contact_button {
                background-image: url("../../assets/images/pc/open.png");
                background-size: 100% 100%;
                width: 115px;
                height: 40px;
                font-size: 18px;
                font-family: Poppins;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
            }
            .contact_bg {
                position: absolute;
                right: 0;
                bottom: 0;
                background-image: url("../../assets/images/pc/huojian.png");
                background-size: 100% 100%;
                width: 115px;
                height: 165px;
            }
        }
        .dialog_invite_friends_div {
            width: 380px;
            height: 387px;
            background: #E19B00;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-around;
            .friend_top {
                width: 6px;
                margin-left: 325px;
                margin-top: -10px;
                cursor: pointer;
                img {
                    width: 16px;
                }
            }
            .friend_title {
                width: 280px;
                height: 25px;
                font-size: 29px;
                font-family: Poppins;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 1px;
                text-align: center;
                margin-top: -30px;
            }
            .friend_text {
                width: 300px;
                height: 155px;
                font-size: 16px;
                font-family: Poppins;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 27px;
                word-wrap: break-word;
                overflow: hidden;
                margin-top: -30px;
                .loading {
                    font-size: 12px;
                    margin-top: -9px;
                    font-weight: 100;
                    margin-left: 115px;
                }
            }
            .friend_button {
                background-image: url("../../assets/images/pc/open.png");
                background-size: 100% 100%;
                width: 115px;
                height: 40px;
                font-size: 18px;
                font-family: Poppins;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
                z-index: 2;
                margin-top: -15px;
            }
            .friend_bg {
                z-index: 1;
                position: absolute;
                left: 0;
                bottom: 0;
                background-image: url("../../assets/images/pc/feidie.png");
                background-size: 100% 100%;
                width: 190px;
                height: 165px;
            }
        }
    }

    @media (max-width: 768px) {
        .Toast {
            .van-dialog {
                width: 300px;
            }

            .sec1 {
                width: 300px;

                .d1 {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 45px;
                        height: 45px;
                    }

                    .img1 {
                        width: 60px;
                        height: 42px;
                    }

                    .img2 {
                        width: 60px;
                        height: 50px;
                    }

                    margin-bottom: 20px;
                }

                .d2 {
                    margin-bottom: 25px;
                }

                .d3 {
                    button {
                        height: 40px;
                        line-height: 35px;
                        font-size: 13px;
                    }
                }
            }
        }
    }
</style>
